<template>
  <div>
    <h2 class="my-4 text-center">{{title}}</h2>
    <ul class="wet-prices-examples" v-smooth-height>
      <li v-for="(text, i) in items" :key="i" v-html="text"></li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    items: Array,
  },
};
</script>
<style lang="scss">
  .wet-prices-examples {
    list-style-type: none;
    padding: 0;
    text-align: center;
    // background: 90% -150% / auto 120% no-repeat url('~@/assets/images/svg/stamp2.svg');

    & strong {
      color: var(--primary);
      font-size: 1.25rem;
    }
  }
</style>
