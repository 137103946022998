<script>
import Drought from '@/views/DroughtView.vue';
import priceExamples from '@/components/sections/main/priceExamples.vue';

export default {
  extends: Drought,
  components: {
    priceExamples,
  },
  data() {
    return {
      buttonappend: 'priceExamples', /** placed after insure button component name */
    };
  },
  computed: {
    mainSection() {
      // set data for mainSection
      const data = this.strings.sections || {};
      const drought = this.strings.drought || {};
      return {
        title: drought.crops_title,
        video: {
          video_icon: data.video_icon,
          video_text_drought: data.video_text_drought,
        },
        form: {
          product_title: drought.crops_select_title,
          date_from_to: drought.date_from_to,
          date_from: drought.date_from,
          date_to: drought.date_to,
        },
        button: drought.insure_btn_alt,
        buttonDataAttr: {
          name: 'data-crop',
          value: this.formData.crop,
        },
      };
    },
    attrs() {
      const { crop } = this.formData;
      const items = (this.strings.drought?.prices_alt || [])
        .filter((p) => p[crop])
        .map((p) => this.format(p.text, `<strong>${p[crop]}</strong>`));
      return {
        title: this.strings.drought?.price_xmpl_title,
        items,
      };
    },
  },
  watch: {
    attrs(v) {
      this.buttonappendAttrs = v;
    },
  },
};
</script>
